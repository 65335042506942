var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" container"},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset-lg":"2","offset-md":"2"}},[_c('Breadcrumbs',{attrs:{"items":[
          {
            text: 'All Students',
            disabled: false,
            href: ("/admin/schools/" + (_vm.$route.params.id) + "/students?name=" + (_vm.$route.query.name))
          },
          {
            text: !!_vm.$route.query.id ? 'Edit Student' : 'Add Student',
            disabled: true
          }
        ]}}),_c('v-row',[(_vm.isPageLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"30","width":"5"}}):_vm._e(),_c('v-col',[_c('div',{staticClass:"d-flex flex-column mb-3 ml-1 mt-n4"},[_c('PageTitle',{staticClass:" font font-weight-bold font-size-lg mb-4",attrs:{"text":!!_vm.$route.query.id ? 'Edit Student Details' : "New Student"}})],1),_c('div',{staticClass:"d-flex flex-column mx-2 flex-grow-1"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addNewStudent)}}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md ma-1",attrs:{"label":"Full Student Name","outlined":"","hint":"Enter full student's name","error-messages":errors[0]},model:{value:(_vm.student.name),callback:function ($$v) {_vm.$set(_vm.student, "name", $$v)},expression:"student.name"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('ValidationProvider',{staticClass:"flex flex-row flex-grow-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md ma-1",attrs:{"label":"ID Number","outlined":"","hint":"Enter student's ID Number","error-messages":errors[0]},model:{value:(_vm.student.idNumber),callback:function ($$v) {_vm.$set(_vm.student, "idNumber", $$v)},expression:"student.idNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary","type":"submit"}},[_c('span',{staticClass:"font font-sm font-weight-medium "},[_vm._v("Save ")])])],1)])]}}])})],1)])],1)],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }