<template>
  <div class=" container">
    <v-row style="width: 100%">
      <v-col cols="8" offset-lg="2" offset-md="2" class="mt-4">
        <Breadcrumbs
          :items="[
            {
              text: 'All Students',
              disabled: false,
              href: `/admin/schools/${$route.params.id}/students?name=${$route.query.name}`
            },
            {
              text: !!$route.query.id ? 'Edit Student' : 'Add Student',
              disabled: true
            }
          ]"
        />
        <v-row>
          <v-progress-circular
            v-if="isPageLoading"
            indeterminate
            color="primary"
            size="30"
            width="5"
          />
          <v-col>
            <div class="d-flex flex-column mb-3 ml-1 mt-n4">
              <PageTitle
                :text="
                  !!$route.query.id ? 'Edit Student Details' : `New Student`
                "
                class=" font font-weight-bold font-size-lg mb-4"
              />
            </div>
            <div class="d-flex flex-column mx-2 flex-grow-1">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(addNewStudent)">
                  <div
                    class="d-flex flex-row flex-grow-1 justify-space-between"
                  >
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Full Student Name"
                        outlined
                        v-model="student.name"
                        hint="Enter full student's name"
                        class="font font-weight-medium font-size-md ma-1"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    class="d-flex flex-row flex-grow-1 justify-space-between"
                  >
                    <ValidationProvider
                      class="flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="ID Number"
                        outlined
                        v-model="student.idNumber"
                        hint="Enter student's ID Number"
                        class="font font-weight-medium font-size-md ma-1"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="d-flex flex-row flex-grow-1">
                    <v-spacer />
                    <v-btn
                      :disabled="isLoading"
                      :loading="isLoading"
                      color="primary"
                      type="submit"
                    >
                      <span class="font font-sm font-weight-medium "
                        >Save
                      </span>
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <!--            <div-->
            <!--              v-if="$route.query.id"-->
            <!--              class="d-flex flex-column mx-2 flex-grow-1 pt-5 "-->
            <!--            >-->
            <!--              <h5 class=" font font-weight-bold font-md  blue-grey&#45;&#45;text ">-->
            <!--                {{-->
            <!--                  !$route.query.id ? "Set Customer PIN" : "Update Customer PIN "-->
            <!--                }}-->
            <!--              </h5>-->
            <!--              <span class=" font font-weight-medium font-sm pb-3"-->
            <!--                >Set customer PIN here, PIN can only be 4 characters long</span-->
            <!--              >-->
            <!--              <ValidationObserver v-slot="{ handleSubmit }">-->
            <!--                <form @submit.prevent="handleSubmit(setPIN)">-->
            <!--                  <div-->
            <!--                    class="d-flex flex-row flex-grow-1 justify-space-between"-->
            <!--                  >-->
            <!--                    <ValidationProvider-->
            <!--                      class="flex flex-row flex-grow-1"-->
            <!--                      v-slot="{ errors }"-->
            <!--                      rules="required|numeric|min:4|max:4"-->
            <!--                    >-->
            <!--                      <v-text-field-->
            <!--                        label="New PIN"-->
            <!--                        outlined-->
            <!--                        :type="show ? 'text' : 'password'"-->
            <!--                        v-model="pin.newPin"-->
            <!--                        hint="Enter members' new PIN "-->
            <!--                        class="font font-weight-medium font-size-md ma-1"-->
            <!--                        :error-messages="errors[0]"-->
            <!--                      />-->
            <!--                    </ValidationProvider>-->
            <!--                    <ValidationProvider-->
            <!--                      class="flex flex-row flex-grow-1"-->
            <!--                      rules="required|numeric|min:4|max:4"-->
            <!--                      v-slot="{ errors }"-->
            <!--                    >-->
            <!--                      <v-text-field-->
            <!--                        label="Confirm PIN"-->
            <!--                        outlined-->
            <!--                        :type="show ? 'text' : 'password'"-->
            <!--                        v-model="pin.confirmPin"-->
            <!--                        hint="Confirm members' new PIN "-->
            <!--                        class="font font-weight-medium font-size-md ma-1"-->
            <!--                        :error-messages="errors[0]"-->
            <!--                      />-->
            <!--                    </ValidationProvider>-->
            <!--                    <v-tooltip bottom>-->
            <!--                      <template v-slot:activator="{ on }">-->
            <!--                        <v-btn-->
            <!--                          v-on="on"-->
            <!--                          icon-->
            <!--                          small-->
            <!--                          @click="show = !show"-->
            <!--                          class="mt-4 ml-2"-->
            <!--                          color="primary"-->
            <!--                        >-->
            <!--                          <i class="material-icons-outlined">{{-->
            <!--                            show ? "visibility_off" : "visibility"-->
            <!--                          }}</i>-->
            <!--                        </v-btn>-->
            <!--                      </template>-->

            <!--                      <span class=" font font-weight-medium font-sm"-->
            <!--                        >Show Password</span-->
            <!--                      >-->
            <!--                    </v-tooltip>-->
            <!--                  </div>-->

            <!--                  <div class="d-flex flex-row flex-grow-1">-->
            <!--                    <v-spacer />-->
            <!--                    <v-btn-->
            <!--                      :disabled="isLoading"-->
            <!--                      :loading="isLoading"-->
            <!--                      color="primary"-->
            <!--                      type="submit"-->
            <!--                    >-->
            <!--                      <span class="font font-sm font-weight-medium "-->
            <!--                        >Save-->
            <!--                      </span>-->
            <!--                    </v-btn>-->
            <!--                  </div>-->
            <!--                </form>-->
            <!--              </ValidationObserver>-->
            <!--            </div>-->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required, email, min, max } from "vee-validate/dist/rules";
import SnackBarMixins from "../../../../mixins/SnackBarMIxins";
import { loadComponent } from "@/utils/helpers";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: `Field is required`
});
extend("numeric", {
  ...numeric,
  message: `Field only accept only numeric characters`
});
extend("min", {
  ...min,
  message: "Invalid input provided"
});
extend("max", {
  ...max,
  message: "Invalid input provided"
});
extend("email", {
  ...email,
  message: `Invalid email address provided`
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadComponent(`component/SnackBar`),
    PageTitle: loadComponent(`component/PageTitle`),
    Breadcrumbs: loadComponent(`component/Breadcrumbs`)
  },
  computed: {
    ...mapGetters({
      isLoading: "getIsLoading",
      isPageLoading: "getIsPageLoading",
      details: "schools/getDetails"
    })
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      menu: false,
      date: null,
      activePicker: null,
      pin: {
        newPin: "",
        confirmPin: ""
      },
      show: false,
      student: {
        idNumber: "",
        name: ""
      }
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    details(payload) {
      this.student = {
        name: payload.name,
        idNumber: payload.idNumber
      };
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    addNewStudent() {
      if (this.$route.query.id && this.$route.query.action) {
        let payload = {
          body: {
            ...this.student
          },
          schoolId: this.$route.params.id ?? this.details?.schoolId ?? "",
          _id: this.$route.query.id ?? this.details._id ?? "",
          schoolName: this.details?.schoolId?.name ?? ""
        };
        this.$store.dispatch("schools/updateStudentDetails", payload);
        return;
      }
      this.$store.dispatch("schools/addNewStudent", {
        schoolId: this.$route.params.id,
        schoolName: this.$route.query?.name,
        ...this.student
      });
    }
  },
  created() {
    if (this.$route.query.id && this.$route.query.action) {
      this.$store.dispatch("schools/getStudentDetails", {
        studentId: this.$route.query.id,
        schoolId: this.$route.params.id
      });
    }
  }
};
</script>
